<template>
  <b-tr>
    <template v-if="isFirtsMatterOfLevel">
      <b-td :rowspan="cycleMattersAmount">
        {{ matter.plan_level }}
      </b-td>
    </template>
    <!-- <template v-if="getFirtsMatterPerFormationArea">
      <td :rowspan="getMaxFormationAreaPerNivel()">
        <div class="wrap-text">
          {{ getFormationArea(matter.formation_area) }}
        </div>
      </td>
    </template> -->
    <!-- <td v-if="false">{{ getCycles(matter.cycle) }}</td> -->
    <b-td v-if="false">{{ matter.plan_level }}</b-td>
    <b-td v-if="false">{{ getFormationArea(matter.formation_area) }}</b-td>
    <!-- <td>{{ getMatterName(matter.matter) }}</td> -->
    <b-td
      class="td-matter p-0"
      v-b-tooltip.top.v-secondary.noninteractive="
        `${$getVisibleNames(
          'mesh.formationarea',
          false,
          'Área De Formación'
        )}: ${getFormationArea(matter.formation_area)}`
      "
    >
      <b-link
        :to="{
          name: 'MatterProgramView',
          params: { matter_id: matter.id },
        }"
      >
        {{ matter.code }} -
        {{ getMatterName(matter.matter).toUpperCase() }}
      </b-link>
    </b-td>
    <b-td class="p-0">
      <div class="cognitive-color-container">
        <div
          v-if="studyUnitsNoCognitiveLevel().length > 0"
          :id="'study_units-' + matter.id + '- 0'"
          class="cognitive-color align-self-center"
          :style="{
            'background-color': '#ebebeb !important',
          }"
        >
          <strong>
            {{ studyUnitsNoCognitiveLevel().length }}
          </strong>
          <b-popover
            :target="'study_units-' + matter.id + '- 0'"
            placement="left"
            triggers="hover focus"
          >
            <template #title>
              {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }} sin
              {{
                $getVisibleNames(
                  "mesh.cognitivelevel",
                  false,
                  "Nivel Cognitivo"
                )
              }}
            </template>
            <div style="overflow: auto; max-height: 350px">
              <div
                v-for="item in studyUnitsNoCognitiveLevel()"
                :key="
                  'study-unit:' + item.id + 'cl:' + '0' + 'matter:' + matter.id
                "
              >
                <SentenceContainer
                  :unique_id="
                    matter.id + '-' + item.id + '-' + item.full_sentence
                  "
                  :Sentence="item"
                ></SentenceContainer>
              </div>
            </div>
          </b-popover>
        </div>
        <div
          v-for="cognitive_level in CognitiveLevelFilter"
          :key="cognitive_level.id"
          :id="'study_units-' + matter.id + '-' + cognitive_level.id"
          class="cognitive-color align-self-center"
          :style="{
            'background-color':
              cognitive_level.color == '' ||
              $equals(cognitive_level.color, '#FFFFFF')
                ? '#ebebeb'
                : cognitive_level.color + ' !important',
            color: $lightOrDark(
              cognitive_level.color == '' ||
                $equals(cognitive_level.color, '#FFFFFF')
                ? '#ebebeb'
                : cognitive_level.color
            )
              ? '#2b2a2a !important'
              : 'white !important',
          }"
        >
          <strong>
            {{ studyUnitsCount(cognitive_level).length }}
          </strong>
          <b-popover
            :target="'study_units-' + matter.id + '-' + cognitive_level.id"
            placement="left"
            triggers="hover focus"
          >
            <template #title>
              {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }} con
              {{
                $getVisibleNames(
                  "mesh.cognitivelevel",
                  false,
                  "Nivel Cognitivo"
                )
              }}:
              {{ cognitive_level.level }}
            </template>
            <div style="overflow: auto; max-height: 350px">
              <div
                v-for="item in studyUnitsCount(cognitive_level)"
                :key="
                  'study-unit:' +
                  item.id +
                  'cl:' +
                  cognitive_level.id +
                  'matter:' +
                  matter.id
                "
              >
                <SentenceContainer
                  :unique_id="
                    matter.id +
                    '-' +
                    item.id +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    item.full_sentence
                  "
                  :Sentence="item"
                ></SentenceContainer>
              </div>
            </div>
          </b-popover>
        </div>
      </div>
    </b-td>
    <template v-for="competence in competences">
      <b-td
        v-if="getCheckCompetence(matter, competence.competence)"
        :key="matter.id + 'c' + competence.id"
        :id="matter.id + 'c' + competence.id"
        class="check-competence p-0"
        :style="{
          'background-color': cognitiveLeveLColor(competence) + ' !important',
        }"
      >
        <b-popover
          :target="matter.id + 'c' + competence.id"
          placement="left"
          triggers="hover focus"
        >
          <template #title>
            <template
              v-if="
                !(
                  (institution && institution.internal_use_id == 'duoc_uc') ||
                  $debug_change_duoc
                )
              "
            >
              {{ $getVisibleNames("mesh.cycle", false, "Ciclo Formativo") }}:
              {{ getCycles(competence.cycle) }}
            </template>
            <template>
              <div v-if="competence.verb == null">
                {{ $getVisibleNames("mesh.competence", false, "Competencia") }}
                sin
                {{
                  $getVisibleNames(
                    "mesh.cognitivelevel",
                    false,
                    "Nivel Cognitivo"
                  )
                }}
                <span v-if="cognitiveLeveLColor(competence) != '#ebebeb'">
                  seleccionado
                </span>
              </div>
              <div v-else>
                {{ $getVisibleNames("mesh.competence", false, "Competencia") }}
                con
                {{
                  $getVisibleNames(
                    "mesh.cognitivelevel",
                    false,
                    "Nivel Cognitivo"
                  )
                }}:
                <strong>
                  {{ getCognitiveLevel(competence.verb) }}
                </strong>
              </div>
            </template>
          </template>
          <div class="d-flex">
            <!-- {{ competence.order + "." }} -->
            <SentenceContainer
              :unique_id="
                matter.id +
                '-' +
                competence.id +
                '-' +
                competence.full_sentence +
                '-' +
                competence.order
              "
              :Sentence="competence"
            >
              <template #order>
                <span class="mr-1">
                  {{
                    competence.profile_cycle
                      ? competence.profile_cycle.order + "." + competence.order
                      : competence.order + "."
                  }}<span
                    v-if="
                      competence.sub_order > 0 &&
                      institution &&
                      institution.show_competence_sub_order
                    "
                    >.{{ competence.sub_order }}</span
                  >
                </span>
              </template>
            </SentenceContainer>
          </div>
        </b-popover>
        <b-icon
          icon="check"
          :style="{
            color: $lightOrDark(cognitiveLeveLColor(competence))
              ? '2b2a2a !important'
              : '#white !important',
          }"
          width="20"
          height="20"
        ></b-icon>
      </b-td>
      <td v-else :key="matter.id + 'c' + competence.id"></td>
    </template>
  </b-tr>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "Matrix3Table",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    matter: {
      type: Object,
      required: true,
    },
    matters: {
      type: Array,
      required: true,
    },
    formationAreas: {
      type: Array,
    },
    cycles: {
      type: Array,
    },
    competences: {
      type: Array,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    // genericCompetence: {
    //   type: Array,
    // },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      matters_list: names.MATTERS,
      taxonomy: names.TAXONOMY,
      studyUnits: names.STUDY_UNITS,
    }),
    studyUnitsFilter() {
      return this.studyUnits.filter((x) => x.matter == this.matter.matter);
    },
    CognitiveLevelFilter() {
      let list = [];
      this.taxonomy.cognitive_levels.forEach((cognitive_level) => {
        if (this.studyUnitsCount(cognitive_level).length > 0)
          list.push(cognitive_level);
      });
      return list;
    },
    cycleMattersAmount() {
      return this.matters.filter(
        (x) =>
          x.plan_level == this.matter.plan_level && x.cycle == this.matter.cycle
      ).length;
    },
    isFirtsMatterOfLevel() {
      if (!this.matter) return false;
      const matter = this.matters
        .filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            x.plan_level == this.matter.plan_level
        )
        .shift();
      if (this.matter.id == matter.id) return true;
      return false;
    },
    getFirtsMatterPerFormationArea() {
      if (!this.matter) return false;
      const matter = this.matters
        .filter(
          (x) =>
            x.formation_area == this.matter.formation_area &&
            x.cycle == this.matter.cycle &&
            x.egress_profile == this.egress_profile_id &&
            x.plan_level == this.matter.plan_level
        )
        .shift();
      if (this.matter.id === matter.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    studyUnitsCount(cognitive_level) {
      return this.studyUnitsFilter.filter((x) =>
        x.verb
          ? cognitive_level.verbs.map((verb) => verb.id).includes(x.verb)
          : cognitive_level.verbs.some((verb) =>
              this.$equals(verb.name, x.action)
            )
      );
    },
    studyUnitsNoCognitiveLevel() {
      let verbs = [];
      if (this.taxonomy) {
        this.taxonomy.cognitive_levels.forEach((cognitive_level) => {
          verbs = verbs.concat(cognitive_level.verbs.map((x) => x.name));
        });
        verbs = [...new Set(verbs)];
      }
      return this.studyUnitsFilter.filter(
        (x) =>
          x.verb == null &&
          !verbs.some((action) => this.$equals(action, x.action))
      );
    },
    getCognitiveLevel(verb_id) {
      if (!verb_id || this.taxonomy == null) return "N/A";
      // Desestructuración para mejorar la legibilidad
      const { cognitive_levels } = this.taxonomy;
      // Determinar el nivel cognitivo basado en el verbo o la acción
      const cognitive_level = cognitive_levels.find((cognitive_level) =>
        cognitive_level.verbs.some((verb) => verb.id == verb_id)
      );
      return cognitive_level ? cognitive_level.level : "N/A";
    },
    cognitiveLeveLColor(competence) {
      if (!competence || this.taxonomy == null) return "#ebebeb";
      // Desestructurar para mayor claridad
      const { cognitive_levels } = this.taxonomy;
      const { verb, action } = competence;
      // Función de utilidad para encontrar el color basado en la competencia
      const findColor = (predicate) =>
        cognitive_levels.find((cognitive_level) =>
          cognitive_level.verbs.some(predicate)
        )?.color;
      // Determinar el color basado en el verbo o la acción
      let color = null;
      if (verb) color = findColor(({ id }) => id == verb);
      else color = findColor(({ name }) => this.$equals(name, action));
      if (!color || this.$equals(color, "#ffffff")) return "#ebebeb";
      return color;
    },
    getMatterName(matter_id) {
      if (matter_id) {
        const matter = this.matters_list.find((x) => x.id == matter_id);
        return matter ? matter.name : "";
      } else return "";
    },
    getCheckCompetence(item, index) {
      const matter = item.competences.filter((x) => x == index).shift();
      if (index === matter) {
        return true;
      }
      return false;
    },
    getFormationArea(area_id) {
      let name = "N/A";
      this.formationAreas.forEach((element) => {
        if (element.id == area_id) {
          name = element.name;
        }
      });
      return name;
    },
    getCycles(cycle) {
      let name = "N/A";
      this.cycles.forEach((element) => {
        if (element.id == cycle) {
          name = element.name;
        }
      });
      return name;
    },
    getMaxFormationAreaPerNivel() {
      let max = 0;
      this.matters.forEach((element) => {
        if (
          element.formation_area == this.matter.formation_area &&
          element.plan_level == this.matter.plan_level &&
          element.cycle == this.matter.cycle
        ) {
          max += 1;
        }
      });
      return max;
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle;
  border: 1px solid black;
  border-width: thin;
  text-align: center;
  /* height: 60px; */
  min-height: 50px;
  min-width: 10x;
  font-size: 12px;
  /* padding: 10px 2px; */
}
.cognitive-color-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: space-around; */
  /* align-items: center; */
}
.cognitive-color {
  height: auto;
  width: 25px;
  margin: 0.2em 0.2em;
  border-radius: 3px;
  /* min-width: */
  /* margin: 1px; */
}
.check-competence {
  background-color: #ebebeb;
  cursor: help;
}
.td-matter {
  text-align: left;
  padding-left: 0.5rem !important;
}
</style>